
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import { useToaster } from '@/composables/useToaster'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { errored, isLoaded, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  FormStateError,
  isFormLoading,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { AssetFileExtended, AssetFilesExtended } from '@/modules/ciso/model/asset-file-metadata.interface'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetFilesEditor',
  components: {
    FormStateAlert,
    DataLoadingError,
    DataLoader,
    PanelSubheading,
  },
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
    getFilesFn: {
      type: Function as PropType<(collectionName: string, assetName: string) => Promise<AssetFilesExtended>>,
      required: true,
    },
    upsertFileFn: {
      type: Function as PropType<
        (collectionName: string, assetName: string, assetType: string, file: File) => Promise<{ code: number }>
      >,
      required: true,
    },
    deleteFileFn: {
      type: Function as PropType<
        (collectionName: string, assetName: string, assetType: string) => Promise<{ code: number }>
      >,
      required: true,
    },
    assetValidatorFn: {
      type: Function as PropType<(assetType: string, file: File) => Promise<true | string>>,
      required: true,
    },
  },
  emits: ['assetsUpdated'],
  setup(props, { emit }) {
    const data = reactive({
      files: LOADING_STATE as LoadingState<AssetFilesExtended>,
      uploadStatus: {} as { [key: string]: FormState },
      actionError: null as FormStateError | null,
      showDeleteModal: false,
      deleteAssetType: null as string | null,
    })

    const fileInputs = ref<{ [key: string]: HTMLInputElement }>({})

    const toaster = useToaster()

    const isAnyFileLoading = computed(() => Object.values(data.uploadStatus).some((form) => isFormLoading(form)))

    watch(isAnyFileLoading, (isAnyLoading, wasAnyLoading) => {
      // last file just finished uploading/deleting
      if (wasAnyLoading && !isAnyLoading) {
        emit('assetsUpdated')
      }
    })

    const loadedFiles = computed<AssetFileExtended[]>(() => {
      if (!isLoaded(data.files)) {
        return []
      }

      return Object.values(data.files.data as AssetFilesExtended).sort((a, b) => a.assetType.localeCompare(b.assetType))
    })

    const loadFiles = async () => {
      try {
        data.uploadStatus = {}
        data.files = LOADING_STATE
        const files = await props.getFilesFn(props.collectionName, props.assetName)
        data.files = loaded(files)

        for (const assetType of Object.keys(files)) {
          data.uploadStatus[assetType] = FORM_INITIAL_STATE
        }
      } catch (e) {
        data.files = errored(e)
      }
    }

    const uploadFile = async (assetType: string) => {
      let input: HTMLInputElement | undefined

      try {
        data.actionError = null
        data.uploadStatus[assetType] = FORM_LOADING_STATE

        input = fileInputs.value[assetType]
        const file = input.files?.[0]

        if (file) {
          const validate = await props.assetValidatorFn(assetType, file)

          if (validate !== true) {
            throw new Error(validate)
          }

          const upload = await props.upsertFileFn(props.collectionName, props.assetName, assetType, file)

          if (upload.code === 201) {
            toaster && toaster.success('File successfully uploaded.')
          } else {
            throw new Error()
          }
        } else {
          toaster && toaster.error('No file selected.')
        }
      } catch (e) {
        console.error(e)
        toaster && toaster.error(`Could not upload the file.`)
        data.actionError = formError((e as ApiError).toString())
      } finally {
        data.uploadStatus[assetType] = FORM_INITIAL_STATE

        // We need to reset the input so that @change event will be fired if user chooses the same file again
        if (input) {
          input.value = ''
        }
      }
    }

    const deleteFile = async (assetType: string) => {
      try {
        data.actionError = null
        data.uploadStatus[assetType] = FORM_LOADING_STATE

        await props.deleteFileFn(props.collectionName, props.assetName, assetType)

        toaster && toaster.success('File successfully deleted.')
      } catch (e) {
        toaster && toaster.error(`Could not delete the file.`)
        data.actionError = formError((e as ApiError).toString())
      } finally {
        data.uploadStatus[assetType] = FORM_INITIAL_STATE
      }
    }

    const isSupportedImgExtension = (ext: string): boolean => {
      const imgExts = ['png', 'gif', 'jpg', 'jpeg', 'bmp', 'svg', 'webp', 'apng', 'avif']

      return imgExts.includes(ext.replaceAll('.', ''))
    }

    loadFiles()

    return {
      data,
      loadedFiles,
      toaster,
      isLoading,
      isLoaded,
      isFormLoading,
      fileInputs,
      uploadFile,
      deleteFile,
      isSupportedImgExtension,
    }
  },
})
