export const ALIGN_X = ['center', 'left', 'right'] as const
export type AlignX = typeof ALIGN_X[number]

export const ALIGN_Y = ['center', 'top', 'bottom'] as const
export type AlignY = typeof ALIGN_Y[number]

export class AssetCollection {
  id?: number
  name: string
  alignX: AlignX
  alignY: AlignY
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<AssetCollection>) {
    this.id = doc.id || undefined
    this.name = doc.name || ''
    this.alignX = doc.alignX || 'center'
    this.alignY = doc.alignY || 'center'
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
  }
}
