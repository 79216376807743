
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteParamValue, RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { getBackground } from '@/modules/ciso/api/assets.api'
import { normalizeRouteParam } from '@/utils/vue'
import GoBack from '@/components/UI/GoBack.vue'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import AssetBackgroundEditor from '@/modules/ciso/components/assets/backgrounds/AssetBackgroundEditor.vue'

export default defineComponent({
  name: 'AssetBackground',
  components: { AssetBackgroundEditor, GoBack, Panel, DataLoadingError, DataLoader },
  setup() {
    const route = useRoute()

    const data = reactive({
      isNewBackground: false,
      collectionName: normalizeRouteParam(route.params.collectionName),
      background: LOADING_STATE as LoadingState<AssetBackground>,
    })

    const title = computed(() => {
      if (route.name === CISORoute.CreateAssetBackground) {
        return 'Create new background'
      } else if (isLoaded(data.background)) {
        return `Background: ${data.collectionName} / ${data.background.data.name}`
      } else {
        return 'Background details'
      }
    })

    const getClonedBackground = async (collectionName: string, backgroundName: string): Promise<AssetBackground> => {
      const res = await getBackground(collectionName, backgroundName)

      return new AssetBackground({
        ...res,
        id: undefined,
      })
    }

    const loadBackground = async (collectionName: string, backgroundName?: string) => {
      try {
        data.background = LOADING_STATE

        if (route.name === CISORoute.CreateAssetBackground || !backgroundName) {
          data.background = loaded(
            backgroundName ? await getClonedBackground(collectionName, backgroundName) : new AssetBackground({})
          )
          data.isNewBackground = true
        } else {
          data.background = loaded(await getBackground(collectionName, backgroundName))
          data.isNewBackground = false
        }
      } catch (e) {
        data.background = errored(e)
      }
    }

    type WatchT = [
      RouteParamValue | RouteParamValue[],
      RouteParamValue | RouteParamValue[],
      RouteRecordName | null | undefined
    ]
    watch(
      (): WatchT => [route.params.collectionName, route.params.backgroundName, route.name],
      ([collectionName, backgroundName, routeName]: WatchT) => {
        if (routeName === CISORoute.CreateAssetBackground || routeName === CISORoute.EditAssetBackground) {
          loadBackground(String(normalizeRouteParam(collectionName)), normalizeRouteParam(backgroundName))
        }

        data.collectionName = normalizeRouteParam(collectionName)
      }
    )

    const refresh = () => {
      loadBackground(
        String(normalizeRouteParam(route.params.collectionName)),
        normalizeRouteParam(route.params.backgroundName)
      )
    }

    refresh()

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
      CISORoute,
      refresh,
    }
  },
})
