
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { useRouter } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetCollection } from '@/modules/ciso/model/asset-collection.model'
import { getAllCollections } from '@/modules/ciso/api/assets.api'
import AssetCollectionsDetailsTable from '@/modules/ciso/components/assets/collections/AssetCollectionsDetailsTable.vue'
import { sortLoadingDataById } from '@/utils/object'

export default defineComponent({
  name: 'AssetManagerHome',
  components: { AssetCollectionsDetailsTable, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      collections: LOADING_STATE as LoadingState<AssetCollection[]>,
    })

    const router = useRouter()

    const goToCollectionCreator = () => {
      router.push({ name: CISORoute.CreateAssetCollection })
    }

    onMounted(async () => {
      try {
        data.collections = loaded(await getAllCollections())
      } catch (e) {
        data.collections = errored(e)
      }
    })

    const sortedCollections = computed(() => sortLoadingDataById(data.collections))

    return {
      data,
      goToCollectionCreator,
      isLoaded,
      isLoading,
      isErrored,
      sortedCollections,
      CISORoute,
    }
  },
})
