import { RouteParamValue } from 'vue-router'

export function normalizeRouteParam(routeParam: RouteParamValue | RouteParamValue[]): string | undefined {
  if (!routeParam || routeParam.length < 1) {
    return undefined
  }

  if (Array.isArray(routeParam)) {
    if (routeParam[0].length < 1) {
      return undefined
    }

    return routeParam[0]
  }

  return routeParam
}
