import { getRequestIdHeaders, simpleCharactrQuery } from '@/utils/fetch'
import { cisoApiUrl } from '@/modules/ciso/api/_shared'
import { AssetCollection } from '@/modules/ciso/model/asset-collection.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { AssetActor } from '@/modules/ciso/model/asset-actor.model'
import { AssetActorFile } from '@/modules/ciso/model/asset-actor-file.model'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { AssetFilesExtended, AssetFilesMetadata } from '@/modules/ciso/model/asset-file-metadata.interface'
import { AssetBackgroundFile, BackgroundAssetType } from '@/modules/ciso/model/asset-background-file.model'
import { AssetImportReport } from '@/modules/ciso/model/assets-import-report.model'
import { AssetActorVariant } from '@/modules/ciso/model/asset-actor-variant.model'

export interface ImportOptions {
  overrideExistingAssets: boolean
}

export async function importAssets(
  file: File,
  options: ImportOptions = { overrideExistingAssets: false }
): Promise<AssetImportReport> {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('overrideExistingAssets', String(options.overrideExistingAssets))

  return axios
    .post(cisoApiUrl('assets/import'), formData, {
      headers: {
        ...getRequestIdHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export async function getCollection(name: string): Promise<AssetCollection> {
  const res = await simpleCharactrQuery<AssetCollection>(cisoApiUrl(`assets/collections/${name}`))

  return new AssetCollection(res)
}

export async function getAllCollections(): Promise<AssetCollection[]> {
  const res = await simpleCharactrQuery<AssetCollection[]>(cisoApiUrl(`assets/collections`))

  return (res || []).map((v) => new AssetCollection(v))
}

export async function createCollection(collection: AssetCollection): Promise<AssetCollection> {
  const payload: Partial<AssetCollection> = {
    ...collection,
  }

  return axios
    .post(cisoApiUrl(`assets/collections`), payload, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetCollection(data))
    .catch(apiErrorCatcher)
}

export async function updateCollection(collection: AssetCollection): Promise<AssetCollection> {
  const payload: Partial<AssetCollection> = {
    ...collection,
  }

  return axios
    .patch(cisoApiUrl(`assets/collections/${payload.name}`), payload, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetCollection(data))
    .catch(apiErrorCatcher)
}

export async function deleteCollection(name: string): Promise<unknown> {
  return axios
    .delete(cisoApiUrl(`assets/collections/${name}`), {
      headers: getRequestIdHeaders(),
    })
    .catch(apiErrorCatcher)
}

export async function getActors(collectionName: string): Promise<AssetActor[]> {
  const res = await simpleCharactrQuery<AssetActor[]>(
    cisoApiUrl(`assets/collections/${collectionName}/actors?ignoreStatus=true`)
  )

  return (res || []).map((v) => new AssetActor(v))
}

export async function getActor(collectionName: string, actorName: string): Promise<AssetActor> {
  const res = await simpleCharactrQuery<AssetActor>(
    cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}?ignoreStatus=true`)
  )

  return new AssetActor(res)
}

export async function createActor(collectionName: string, actor: AssetActor): Promise<AssetActor> {
  return axios
    .post(cisoApiUrl(`assets/collections/${collectionName}/actors`), actor, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetActor(data))
    .catch(apiErrorCatcher)
}

export async function updateActor(collectionName: string, actor: AssetActor): Promise<AssetActor> {
  return axios
    .patch(cisoApiUrl(`assets/collections/${collectionName}/actors/${actor.name}`), actor, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetActor(data))
    .catch(apiErrorCatcher)
}

export async function deleteActor(collectionName: string, actorName: string): Promise<unknown> {
  return axios
    .delete(cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}`), {
      headers: getRequestIdHeaders(),
    })
    .catch(apiErrorCatcher)
}

export function getActorAssetsMetadata(): Promise<AssetFilesMetadata> {
  return simpleCharactrQuery<AssetFilesMetadata>(cisoApiUrl('assets/actors/metadata'))
}

export async function getActorFiles(collectionName: string, actorName: string): Promise<AssetFilesExtended> {
  const allAssets = await getActorAssetsMetadata()

  const files = await simpleCharactrQuery<AssetActorFile[]>(
    cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}/files?ignoreStatus=true`)
  )

  const result: AssetFilesExtended = {}

  for (const assetType of Object.keys(allAssets)) {
    const file = (files || []).find((f) => f.assetType === assetType)

    if (file) {
      result[assetType] = { ...allAssets[assetType], ...file, lastModified: new Date(file.lastModified as Date) }
    } else {
      result[assetType] = {
        ...allAssets[assetType],
        assetType,
        url: null,
        s3Key: null,
        lastModified: null,
        required: allAssets[assetType].required,
      }
    }
  }

  return result
}

export async function upsertActorAssetFile(
  collectionName: string,
  actorName: string,
  assetType: string,
  file: File
): Promise<{ code: number }> {
  return axios
    .post(cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}/files/${assetType}`), file, {
      headers: {
        ...getRequestIdHeaders(),
        'Content-Type': file.type || 'text/plain',
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export async function getActorVariants(collectionName: string, actorName: string): Promise<AssetActorVariant[]> {
  const res = await simpleCharactrQuery<AssetActorVariant[]>(
    cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}/variants?ignoreStatus=true`)
  )

  return (res || []).map((v) => new AssetActorVariant(v))
}

export async function importActorVariants(collectionName: string, actorName: string, file: File): Promise<unknown> {
  const formData = new FormData()
  formData.append('file', file)

  return axios
    .post(cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}/variants/import`), formData, {
      headers: {
        ...getRequestIdHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export async function deleteActorAssetFile(
  collectionName: string,
  actorName: string,
  assetType: string
): Promise<{ code: number }> {
  return axios
    .delete(cisoApiUrl(`assets/collections/${collectionName}/actors/${actorName}/files/${assetType}`), {
      headers: {
        ...getRequestIdHeaders(),
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export async function getBackgrounds(collectionName: string): Promise<AssetBackground[]> {
  const res = await simpleCharactrQuery<AssetBackground[]>(
    cisoApiUrl(`assets/collections/${collectionName}/backgrounds`)
  )

  return (res || []).map((v) => new AssetBackground(v))
}

export async function getBackground(collectionName: string, backgroundName: string): Promise<AssetBackground> {
  const res = await simpleCharactrQuery<AssetBackground>(
    cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${backgroundName}`)
  )

  return new AssetBackground(res)
}

export function getBackgroundAssetFileUrl(
  collectionName: string,
  backgroundName: string,
  assetType: BackgroundAssetType
): string {
  return `${process.env.VUE_APP_ASSETS_URL}/v1/${encodeURIComponent(collectionName)}/background/${encodeURIComponent(
    backgroundName
  )}/${encodeURIComponent(assetType)}.jpg`
}

export function getActorIconAssetFileUrl(collectionName: string, actorName: string): string {
  return `${process.env.VUE_APP_ASSETS_URL}/v1/${encodeURIComponent(collectionName)}/actor/${encodeURIComponent(
    actorName
  )}/act_icon_web.png`
}

export async function createBackground(collectionName: string, background: AssetBackground): Promise<AssetBackground> {
  return axios
    .post(cisoApiUrl(`assets/collections/${collectionName}/backgrounds`), background, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetBackground(data))
    .catch(apiErrorCatcher)
}

export async function updateBackground(collectionName: string, background: AssetBackground): Promise<AssetBackground> {
  return axios
    .patch(cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${background.name}`), background, {
      headers: getRequestIdHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new AssetBackground(data))
    .catch(apiErrorCatcher)
}

export async function deleteBackground(collectionName: string, backgroundName: string): Promise<unknown> {
  return axios
    .delete(cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${backgroundName}`), {
      headers: getRequestIdHeaders(),
    })
    .catch(apiErrorCatcher)
}

export function getBackgroundAssetsMetadata(): Promise<AssetFilesMetadata> {
  return simpleCharactrQuery<AssetFilesMetadata>(cisoApiUrl('assets/backgrounds/metadata'))
}

export async function getBackgroundFiles(collectionName: string, backgroundName: string): Promise<AssetFilesExtended> {
  const allAssets = await getBackgroundAssetsMetadata()

  const files = await simpleCharactrQuery<AssetBackgroundFile[]>(
    cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${backgroundName}/files`)
  )

  const result: AssetFilesExtended = {}

  for (const assetType of Object.keys(allAssets)) {
    const file = (files || []).find((f) => f.assetType === assetType)

    if (file) {
      result[assetType] = { ...allAssets[assetType], ...file, lastModified: new Date(file.lastModified as Date) }
    } else {
      result[assetType] = {
        ...allAssets[assetType],
        assetType,
        url: null,
        s3Key: null,
        lastModified: null,
        required: false,
      }
    }
  }

  return result
}

export async function upsertBackgroundAssetFile(
  collectionName: string,
  backgroundName: string,
  assetType: string,
  file: File
): Promise<{ code: number }> {
  return axios
    .post(cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${backgroundName}/files/${assetType}`), file, {
      headers: {
        ...getRequestIdHeaders(),
        'Content-Type': file.type || 'text/plain',
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export async function deleteBackgroundAssetFile(
  collectionName: string,
  backgroundName: string,
  assetType: string
): Promise<{ code: number }> {
  return axios
    .delete(cisoApiUrl(`assets/collections/${collectionName}/backgrounds/${backgroundName}/files/${assetType}`), {
      headers: {
        ...getRequestIdHeaders(),
      },
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}
