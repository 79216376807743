import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f4d40d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "actors"
}
const _hoisted_2 = {
  key: 2,
  class: "backgrounds"
}
const _hoisted_3 = {
  key: 3,
  class: "migration"
}
const _hoisted_4 = { class: "migrate-checkbox row align-center" }
const _hoisted_5 = { class: "migrate-checkbox row align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_AssetActorsDetailsTable = _resolveComponent("AssetActorsDetailsTable")!
  const _component_AssetBackgroundsDetailsTable = _resolveComponent("AssetBackgroundsDetailsTable")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_separator = _resolveComponent("va-separator")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("General settings")
            ]),
            _: 1
          }),
          (!_ctx.isNewCollection)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 0,
                bordered: "",
                class: "mb-3",
                label: "ID",
                modelValue: _ctx.localCollection.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localCollection.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Name",
            modelValue: _ctx.localCollection.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localCollection.name) = $event)),
            disabled: !_ctx.isNewCollection
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_select, {
            bordered: "",
            class: "mb-3",
            label: "Horizontal alignment",
            modelValue: _ctx.localCollection.alignX,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localCollection.alignX) = $event)),
            options: _ctx.ALIGN_X
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_va_select, {
            bordered: "",
            class: "mb-3",
            label: "Vertical alignment",
            modelValue: _ctx.localCollection.alignY,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localCollection.alignY) = $event)),
            options: _ctx.ALIGN_Y
          }, null, 8, ["modelValue", "options"]),
          (!_ctx.isNewCollection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Actors "),
                    _createVNode(_component_va_button, {
                      class: "ml-4",
                      color: "success",
                      size: "small",
                      onClick: _ctx.goToActorCreator
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Add new actor")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AssetActorsDetailsTable, {
                  collectionName: _ctx.localCollection.name,
                  actors: _ctx.actors,
                  onSelectedRowsIds: _cache[4] || (_cache[4] = (val) => (_ctx.selectedActors = val))
                }, null, 8, ["collectionName", "actors"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewCollection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Backgrounds "),
                    _createVNode(_component_va_button, {
                      class: "ml-4",
                      color: "success",
                      size: "small",
                      onClick: _ctx.goToBackgroundCreator
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Add new background ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AssetBackgroundsDetailsTable, {
                  "collection-name": _ctx.localCollection.name,
                  backgrounds: _ctx.backgrounds,
                  onSelectedRowsIds: _cache[5] || (_cache[5] = (val) => (_ctx.selectedBackgrounds = val))
                }, null, 8, ["collection-name", "backgrounds"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewCollection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Migration")
                  ]),
                  _: 1
                }),
                (_ctx.migrationErr)
                  ? (_openBlock(), _createBlock(_component_va_alert, {
                      key: 0,
                      color: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.migrationErr), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("label", _hoisted_4, [
                  _createVNode(_component_va_checkbox, {
                    class: "mr-2",
                    modelValue: _ctx.migrationConfig.migrateActorVariants,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.migrationConfig.migrateActorVariants) = $event))
                  }, null, 8, ["modelValue"]),
                  _createTextVNode(" Migrate actor variants (might take a while) ")
                ]),
                _createElementVNode("label", _hoisted_5, [
                  _createVNode(_component_va_checkbox, {
                    class: "mr-2",
                    modelValue: _ctx.migrationConfig.updateOnConflict,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.migrationConfig.updateOnConflict) = $event))
                  }, null, 8, ["modelValue"]),
                  _createTextVNode(" Override assets that already exist in the destination ")
                ]),
                _createVNode(_component_va_button, {
                  class: "mt-2",
                  onClick: _cache[8] || (_cache[8] = () => (_ctx.data.showMigrateModal = true)),
                  icon: "drive_file_move"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Migrate selected assets " + _toDisplayString(_ctx.migrationDirection), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_separator),
          (!_ctx.isNewCollection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[9] || (_cache[9] = () => _ctx.actionHandler(_ctx.saveCollectionHandler)),
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Save ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                  color: "danger",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete ")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isNewCollection)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 5,
                class: "mr-2",
                onClick: _cache[11] || (_cache[11] = () => _ctx.actionHandler(_ctx.createCollectionHandler)),
                color: "success",
                disabled: _ctx.isFormLoading(_ctx.data.formState)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create ")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to delete this collection?",
        onOk: _cache[13] || (_cache[13] = () => _ctx.actionHandler(_ctx.deleteCollectionHandler))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showMigrateModal,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.showMigrateModal) = $event)),
        title: "Confirm",
        message: 'Are you sure you want to migrate this collection ' + _ctx.migrationDirection + '?',
        onOk: _ctx.migrateSelectedAssets
      }, null, 8, ["modelValue", "message", "onOk"])
    ])
  ]))
}