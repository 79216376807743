
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetActor } from '@/modules/ciso/model/asset-actor.model'
import { getActorIconAssetFileUrl } from '@/modules/ciso/api/assets.api'

export default defineComponent({
  name: 'AssetActorsDetailsTable',
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    actors: {
      type: Array as PropType<AssetActor[]>,
      required: true,
    },
  },
  emits: ['selectedRowsIds'],
  setup(props, { emit }) {
    const router = useRouter()

    const selectedRows = reactive({} as { [key: string]: boolean })

    watch(selectedRows, () => {
      emit(
        'selectedRowsIds',
        Object.entries(selectedRows)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, value]) => Boolean(value))
          .map(([key]) => Number(key))
      )
    })

    const selectAll = () => {
      props.actors.forEach((actor) => (selectedRows[String(actor.id)] = true))
    }

    const deselectAll = () => {
      props.actors.forEach((actor) => (selectedRows[String(actor.id)] = false))
    }

    const allSelected = computed(() => Object.values(selectedRows).filter(Boolean).length >= props.actors.length)

    const navigateToActor = (collectionName: string, actorName: string) => {
      router.push({ name: CISORoute.EditAssetActor, params: { collectionName, actorName } })
    }

    return { navigateToActor, getActorIconAssetFileUrl, selectedRows, allSelected, selectAll, deselectAll }
  },
})
