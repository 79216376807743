export class AssetActorVariant {
  id?: number
  name: string
  type: string
  data: Record<string, string>
  assetActorId?: number
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<AssetActorVariant>) {
    this.id = doc.id || undefined
    this.assetActorId = doc.assetActorId || undefined
    this.name = doc.name || ''
    this.type = doc.type || ''
    this.data = doc.data || {}
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
  }
}
