
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteParamValue, RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { getActorVariants, importActorVariants } from '@/modules/ciso/api/assets.api'
import { normalizeRouteParam } from '@/utils/vue'
import GoBack from '@/components/UI/GoBack.vue'
import { AssetActorVariant } from '@/modules/ciso/model/asset-actor-variant.model'
import AssetActorVariantsList from '@/modules/ciso/components/assets/actors/AssetActorVariantsList.vue'
import { FORM_INITIAL_STATE, FORM_LOADING_STATE, formError, FormState, formSuccess } from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetActorVariants',
  components: { FormStateAlert, AssetActorVariantsList, GoBack, Panel, DataLoadingError, DataLoader },
  setup() {
    const route = useRoute()

    const data = reactive({
      collectionName: normalizeRouteParam(route.params.collectionName),
      actorName: normalizeRouteParam(route.params.actorName),
      variants: LOADING_STATE as LoadingState<AssetActorVariant[]>,
      formState: FORM_INITIAL_STATE as FormState,
    })

    const loadVariants = async (collectionName: string, actorName: string) => {
      try {
        data.variants = LOADING_STATE
        data.variants = loaded(await getActorVariants(collectionName, actorName))
      } catch (e) {
        data.variants = errored(e)
      }
    }

    const files = computed<File[]>({
      get: () => [],
      set: (files: File[]) => {
        if (files.length === 1) {
          handleImport(files[0])
        } else if (files.length !== 0) {
          data.formState = formError('Something went wrong: invalid number of files in the form.')
        }
      },
    })

    const handleImport = async (file: File) => {
      try {
        data.formState = FORM_LOADING_STATE
        await importActorVariants(String(data.collectionName), String(data.actorName), file)
        data.formState = formSuccess(`Variants imported!`)
        await loadVariants(String(data.collectionName), String(data.actorName))
      } catch (e) {
        data.formState = formError((e as ApiError).toString())
      }
    }

    type WatchT = [
      RouteParamValue | RouteParamValue[],
      RouteParamValue | RouteParamValue[],
      RouteRecordName | null | undefined
    ]
    watch(
      (): WatchT => [route.params.collectionName, route.params.actorName, route.name],
      ([collectionName, actorName]: WatchT) => {
        data.collectionName = normalizeRouteParam(collectionName)
        data.actorName = normalizeRouteParam(actorName)

        loadVariants(String(data.collectionName), String(data.actorName))
      }
    )

    loadVariants(String(data.collectionName), String(data.actorName))

    return {
      data,
      isLoading,
      isLoaded,
      isErrored,
      CISORoute,
      files,
    }
  },
})
