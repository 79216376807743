
import { defineComponent } from 'vue'
import Panel from '@/components/UI/Panel.vue'
import GoBack from '@/components/UI/GoBack.vue'
import { CISORoute } from '@/modules/ciso/routes'

export default defineComponent({
  name: 'AssetExport',
  components: { GoBack, Panel },
  setup() {
    return { CISORoute }
  },
})
