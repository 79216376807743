import { simpleCharactrQuery } from '@/utils/fetch'
import { migratorApiUrl } from '@/modules/ciso/api/_shared'
import { AssetMigrationStatus, AssetMigrationStatusType } from '@/modules/ciso/model/asset-migration-status.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'

export interface AssetsMigrationConfig {
  updateOnConflict: boolean
  migrateActorVariants: boolean
}

export async function getMigrationStatus(type: AssetMigrationStatusType): Promise<AssetMigrationStatus> {
  const suffix = type === AssetMigrationStatusType.Previous ? '/prev' : ''

  const res = await simpleCharactrQuery<AssetMigrationStatus>(migratorApiUrl(`migration${suffix}`))

  return new AssetMigrationStatus(res)
}

export async function migrateAssets(
  collectionID: number,
  actorIDs: number[],
  backgroundIDs: number[],
  config: AssetsMigrationConfig
): Promise<boolean> {
  const payload = {
    config: {
      direction: process.env.VUE_APP_ASSET_MIGRATION_DIRECTION,
      updateOnConflict: config.updateOnConflict,
      migrateActorVariants: config.migrateActorVariants,
    },
    data: {
      collections: [collectionID],
      actors: actorIDs,
      backgrounds: backgroundIDs,
    },
  }

  return axios
    .post(migratorApiUrl(`migration`), payload)
    .then(() => true)
    .catch(apiErrorCatcher)
}
