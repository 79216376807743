import { capitalizeFirstLetter, splitCamelCase } from '@/utils/string'

export type AssetImportReport = Record<string, string | number>

/*
 * @example
 * Input: { fooBar: 12, bazBar: 136 }
 * Output: "Foo bar: 12\nBaz bar: 136"
 */
export function formatAssetImportReport(report: AssetImportReport): string {
  return Object.entries(report)
    .map((entry) => {
      const keyWords = splitCamelCase(entry[0])
      keyWords[0] = capitalizeFirstLetter(keyWords[0])

      const key = keyWords.join(' ')
      const value = entry[1]

      return `${key}: ${value}`
    })
    .join('\n')
}
