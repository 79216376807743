import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06c7c5bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "subheading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_progress_bar = _resolveComponent("va-progress-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bars, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.key,
        class: "progress-item"
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(item.heading), 1),
        _createVNode(_component_va_progress_bar, {
          size: "large",
          "model-value": _ctx.getPercent(_ctx.status[item.key]),
          "content-inside": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getPercent(_ctx.status[item.key])) + "% ", 1)
          ]),
          _: 2
        }, 1032, ["model-value"])
      ]))
    }), 128))
  ]))
}