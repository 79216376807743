
import { defineComponent, onMounted, onUnmounted, reactive } from 'vue'
import { errored, isErrored, isLoaded, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { getMigrationStatus } from '@/modules/ciso/api/assets-migration.api'
import {
  AssetMigrationState,
  AssetMigrationStatus,
  AssetMigrationStatusType,
} from '@/modules/ciso/model/asset-migration-status.model'
import AssetMigrationReport from '@/modules/ciso/components/assets/migrator/AssetMigrationReport.vue'
import AssetMigrationProgress from '@/modules/ciso/components/assets/migrator/AssetMigrationProgress.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'

export default defineComponent({
  name: 'AssetMigrationStatus',
  components: { DataLoadingError, AssetMigrationProgress, AssetMigrationReport, Panel },
  setup() {
    const data = reactive({
      migrationStatus: LOADING_STATE as LoadingState<AssetMigrationStatus>,
      prevMigrationStatus: LOADING_STATE as LoadingState<AssetMigrationStatus>,
    })

    const loadMigrationStatus = async (type: AssetMigrationStatusType) => {
      switch (type) {
        case AssetMigrationStatusType.Current:
          try {
            data.migrationStatus = loaded(await getMigrationStatus(type))
          } catch (e) {
            data.migrationStatus = errored(e)
          }
          break
        case AssetMigrationStatusType.Previous:
          try {
            data.prevMigrationStatus = loaded(await getMigrationStatus(type))
          } catch (e) {
            data.prevMigrationStatus = errored(e)
          }
      }
    }

    // eslint-disable-next-line
    let intervalId: any

    onMounted(() => {
      loadMigrationStatus(AssetMigrationStatusType.Current)
      loadMigrationStatus(AssetMigrationStatusType.Previous)

      intervalId = setInterval(() => {
        loadMigrationStatus(AssetMigrationStatusType.Current)
        loadMigrationStatus(AssetMigrationStatusType.Previous)
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    return {
      data,
      isLoaded,
      isErrored,
      CISORoute,
      AssetMigrationState,
    }
  },
})
