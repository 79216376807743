
import { computed, defineComponent, PropType } from 'vue'
import { AssetMigrationState, AssetMigrationStatus } from '@/modules/ciso/model/asset-migration-status.model'
import { dateDiffInSeconds, formatSecondsDuration } from '@/utils/time'

export default defineComponent({
  name: 'AssetMigrationReport',
  props: {
    report: {
      type: Object as PropType<AssetMigrationStatus>,
      required: true,
    },
  },
  setup(props) {
    const result = computed(() => {
      switch (props.report.state) {
        case AssetMigrationState.Success:
          if (props.report.errors.length > 0) {
            return { text: 'Partially successful', color: 'warning' }
          }

          if (
            props.report.collections.current < props.report.collections.total ||
            props.report.actors.current < props.report.actors.total ||
            props.report.actorVariants.current < props.report.actorVariants.total ||
            props.report.backgrounds.current < props.report.backgrounds.total
          ) {
            return { text: 'Partially successful', color: 'warning' }
          }

          return { text: 'Success', color: 'success' }
        case AssetMigrationState.Failure:
          return { text: 'Failure', color: 'danger' }
        default:
          return { text: 'Unknown state', color: 'danger' }
      }
    })

    const totalMigrationTime = computed(() =>
      formatSecondsDuration(dateDiffInSeconds(props.report.startedAt, props.report.finishedAt))
    )

    return { result, totalMigrationTime }
  },
})
