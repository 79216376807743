
import { defineComponent, PropType } from 'vue'
import { AssetActorVariant } from '@/modules/ciso/model/asset-actor-variant.model'

export default defineComponent({
  name: 'AssetActorVariantsList',
  props: {
    variants: {
      type: Array as PropType<AssetActorVariant[]>,
      required: true,
    },
  },
})
