import { ActorAssetTypeToHash } from '@/modules/ciso/model/asset-actor-file.model'

export class AssetBackground {
  id?: number
  name: string
  scale: number
  offsetX: number
  offsetY: number
  uploadedAssets: ActorAssetTypeToHash
  assetSlotsCount: number
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<AssetBackground>) {
    this.id = doc.id || undefined
    this.name = doc.name || ''
    this.scale = doc.scale || 1
    this.offsetX = doc.offsetX || 0
    this.offsetY = doc.offsetY || 0
    this.uploadedAssets = doc.uploadedAssets ?? {}
    this.assetSlotsCount = doc.assetSlotsCount ?? 0
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
  }

  get uploadedAssetsCount() {
    return Object.keys(this.uploadedAssets).length
  }
}
