import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBack = _resolveComponent("GoBack")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_AssetBackgroundEditor = _resolveComponent("AssetBackgroundEditor")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_GoBack, {
        to: { name: _ctx.CISORoute.EditAssetCollection, params: { collectionName: _ctx.data.collectionName } }
      }, null, 8, ["to"]),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.background)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.background
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.background))
        ? (_openBlock(), _createBlock(_component_AssetBackgroundEditor, {
            key: 0,
            "collection-name": _ctx.data.collectionName,
            background: _ctx.data.background.data,
            "is-new-background": _ctx.data.isNewBackground,
            onRefreshBackground: _ctx.refresh
          }, null, 8, ["collection-name", "background", "is-new-background", "onRefreshBackground"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}