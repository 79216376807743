
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteParamValue, RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetCollection } from '@/modules/ciso/model/asset-collection.model'
import AssetCollectionEditor from '@/modules/ciso/components/assets/collections/AssetCollectionEditor.vue'
import { getActors, getBackgrounds, getCollection } from '@/modules/ciso/api/assets.api'
import { AssetActor } from '@/modules/ciso/model/asset-actor.model'
import { normalizeRouteParam } from '@/utils/vue'
import GoBack from '@/components/UI/GoBack.vue'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { sortLoadingDataById } from '@/utils/object'

export default defineComponent({
  name: 'Asset collection',
  components: { GoBack, AssetCollectionEditor, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewCollection: false,
      collection: LOADING_STATE as LoadingState<AssetCollection>,
      actors: LOADING_STATE as LoadingState<AssetActor[]>,
      backgrounds: LOADING_STATE as LoadingState<AssetBackground[]>,
    })

    const route = useRoute()

    const title = computed(() => {
      if (route.name === CISORoute.CreateAssetCollection) {
        return 'Create new collection'
      } else if (isLoaded(data.collection)) {
        return 'Collection: ' + data.collection.data.name
      } else {
        return 'Collection details'
      }
    })

    const isAnyLoading = computed(
      () => isLoading(data.collection) || isLoading(data.actors) || isLoading(data.backgrounds)
    )
    const isAllLoaded = computed(() => isLoaded(data.collection) && isLoaded(data.actors) && isLoaded(data.backgrounds))

    const loadAll = async (collectionName?: string) => {
      await Promise.all([loadCollection(collectionName), loadActors(collectionName), loadBackgrounds(collectionName)])
    }

    const loadCollection = async (name?: string) => {
      try {
        data.collection = LOADING_STATE

        if (route.name === CISORoute.CreateAssetCollection) {
          data.collection = loaded(new AssetCollection({}))
          data.isNewCollection = true
        } else if (name) {
          data.collection = loaded(await getCollection(name))
          data.isNewCollection = false
        }
      } catch (e) {
        data.collection = errored(e)
      }
    }

    const loadActors = async (collectionName?: string) => {
      try {
        data.actors = LOADING_STATE

        if (route.name !== CISORoute.CreateAssetCollection && collectionName) {
          data.actors = loaded(await getActors(collectionName))
        } else {
          data.actors = loaded([])
        }
      } catch (e) {
        data.actors = errored(e)
      }
    }

    const loadBackgrounds = async (collectionName?: string) => {
      try {
        data.backgrounds = LOADING_STATE

        if (route.name !== CISORoute.CreateAssetCollection && collectionName) {
          data.backgrounds = loaded(await getBackgrounds(collectionName))
        } else {
          data.backgrounds = loaded([])
        }
      } catch (e) {
        data.backgrounds = errored(e)
      }
    }

    type WatchT = [RouteParamValue | RouteParamValue[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.collectionName, route.name],
      ([collectionName, routeName]: WatchT) => {
        if (routeName === CISORoute.CreateAssetCollection || routeName === CISORoute.EditAssetCollection) {
          loadAll(normalizeRouteParam(collectionName))
        }
      }
    )

    loadAll(normalizeRouteParam(route.params.collectionName))

    const sortedActors = computed(() => sortLoadingDataById(data.actors))
    const sortedBackgrounds = computed(() => sortLoadingDataById(data.backgrounds))

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
      isAnyLoading,
      isAllLoaded,
      CISORoute,
      sortedActors,
      sortedBackgrounds,
    }
  },
})
