
import { computed, defineComponent, reactive, ref, unref, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteParamValue, RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { getActor, getBackgrounds } from '@/modules/ciso/api/assets.api'
import { AssetActor } from '@/modules/ciso/model/asset-actor.model'
import { normalizeRouteParam } from '@/utils/vue'
import AssetActorEditor from '@/modules/ciso/components/assets/actors/AssetActorEditor.vue'
import GoBack from '@/components/UI/GoBack.vue'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { Player } from '@charactr/unity-player-lib'
import '@charactr/unity-player-lib/styles'

export default defineComponent({
  name: 'AssetActor',
  components: { Player, GoBack, AssetActorEditor, Panel, DataLoadingError, DataLoader },
  setup() {
    const route = useRoute()

    const player = ref()

    const data = reactive({
      isNewActor: false,
      collectionName: normalizeRouteParam(route.params.collectionName),
      actor: LOADING_STATE as LoadingState<AssetActor>,
      backgrounds: LOADING_STATE as LoadingState<AssetBackground[]>,
      showActorPreview: false,
      playerError: '',
    })

    const isAllLoaded = computed(() => isLoaded(data.actor) && isLoaded(data.backgrounds))
    const isAnyLoading = computed(() => isLoading(data.actor) || isLoading(data.backgrounds))

    const title = computed(() => {
      if (route.name === CISORoute.CreateAssetActor) {
        return 'Create new actor'
      } else if (isLoaded(data.actor)) {
        return `Actor: ${data.collectionName} / ${data.actor.data.displayName}`
      } else {
        return 'Actor details'
      }
    })

    const getClonedActor = async (collectionName: string, actorName: string): Promise<AssetActor> => {
      const res = await getActor(collectionName, actorName)

      return new AssetActor({
        ...res,
        id: undefined,
      })
    }

    const loadAll = async (collectionName: string, actorName?: string) => {
      await Promise.all([loadActor(collectionName, actorName), loadBackgrounds(collectionName)])
    }

    const loadActor = async (collectionName: string, actorName?: string) => {
      try {
        data.actor = LOADING_STATE

        if (route.name === CISORoute.CreateAssetActor || !actorName) {
          data.actor = loaded(actorName ? await getClonedActor(collectionName, actorName) : new AssetActor({}))
          data.isNewActor = true
        } else {
          data.actor = loaded(await getActor(collectionName, actorName))
          data.isNewActor = false
        }
      } catch (e) {
        data.actor = errored(e)
      }
    }

    const loadBackgrounds = async (collectionName: string) => {
      try {
        data.backgrounds = LOADING_STATE
        data.backgrounds = loaded(await getBackgrounds(collectionName))
      } catch (e) {
        data.actor = errored(e)
      }
    }

    type WatchT = [
      RouteParamValue | RouteParamValue[],
      RouteParamValue | RouteParamValue[],
      RouteRecordName | null | undefined
    ]
    watch(
      (): WatchT => [route.params.collectionName, route.params.actorName, route.name],
      ([collectionName, actorName, routeName]: WatchT) => {
        if (routeName === CISORoute.CreateAssetActor || routeName === CISORoute.EditAssetActor) {
          loadAll(String(normalizeRouteParam(collectionName)), normalizeRouteParam(actorName))
        }

        data.collectionName = normalizeRouteParam(collectionName)
      }
    )

    const refresh = () => {
      loadAll(String(normalizeRouteParam(route.params.collectionName)), normalizeRouteParam(route.params.actorName))
    }

    const playerReady = () => {
      if (isLoaded(data.actor) && data.collectionName) {
        const playerInstance = unref(player)

        playerInstance.loadCollectionActor(data.collectionName, data.actor.data.name)
        playerInstance.setAudio(
          '{"audioSize":61497,"audioUrl":"https://diglett.charactr.dev/v1/share/221205/01GKHQ5TNEDJA9MC65S83QF97T.mp3"}'
        )
      }
    }

    refresh()

    return {
      data,
      title,
      isAllLoaded,
      isAnyLoading,
      isLoaded,
      isErrored,
      CISORoute,
      refresh,
      player,
      playerReady,
    }
  },
})
