export enum AssetMigrationStatusType {
  Previous = 'previous',
  Current = 'current',
}

export enum AssetMigrationState {
  Idle = 'idle',
  InProgress = 'in-progress',
  Success = 'success',
  Failure = 'failure',
}

export interface AssetMigrationProgress {
  current: number
  total: number
}

export class AssetMigrationStatus {
  state: AssetMigrationState
  errors: string[]
  collections: AssetMigrationProgress
  actors: AssetMigrationProgress
  backgrounds: AssetMigrationProgress
  actorVariants: AssetMigrationProgress
  files: AssetMigrationProgress
  startedAt: Date
  finishedAt: Date

  constructor(doc: Partial<AssetMigrationStatus>) {
    this.state = doc.state || AssetMigrationState.Idle
    this.errors = doc.errors || []
    this.collections = doc.collections || { current: 0, total: 0 }
    this.actors = doc.actors || { current: 0, total: 0 }
    this.backgrounds = doc.backgrounds || { current: 0, total: 0 }
    this.actorVariants = doc.actorVariants || { current: 0, total: 0 }
    this.files = doc.files || { current: 0, total: 0 }
    this.startedAt = doc.startedAt ? new Date(doc.startedAt) : new Date()
    this.finishedAt = doc.finishedAt ? new Date(doc.finishedAt) : new Date()
  }
}
