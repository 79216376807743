import { fileToImg } from '@/utils/img'
import { AssetFile } from '@/modules/ciso/model/asset-file-metadata.interface'

export type BackgroundAssetType = 'bg_primary'

export class AssetBackgroundFile implements AssetFile {
  assetType: string
  s3Key: string | null
  url: string | null
  lastModified: Date | null
  required: boolean

  constructor(doc: AssetBackgroundFile) {
    this.assetType = doc.assetType
    this.s3Key = doc.s3Key
    this.url = doc.url
    this.lastModified = doc.lastModified
    this.required = doc.required
  }
}

export async function validateBackgroundAssetFile(assetType: BackgroundAssetType, file: File): Promise<true | string> {
  switch (assetType) {
    case 'bg_primary':
      {
        if (file.type !== 'image/jpeg') {
          return 'Invalid file MIME type. Expected image/jpeg.'
        }

        const img = await fileToImg(file)

        if (img.width > 5000 || img.height > 5000) {
          return 'Maximum dimensions are 5000px x 5000px.'
        }
      }
      break
    default:
      return 'Unknown asset type.'
  }

  return true
}
