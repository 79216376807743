
import { defineComponent, PropType } from 'vue'
import { AssetMigrationProgress, AssetMigrationStatus } from '@/modules/ciso/model/asset-migration-status.model'

export default defineComponent({
  name: 'AssetMigrationProgress',
  props: {
    status: {
      type: Object as PropType<AssetMigrationStatus>,
      required: true,
    },
  },
  setup() {
    const bars = [
      {
        heading: 'Collection rows migration',
        key: 'collections',
      },
      {
        heading: 'Background rows migration',
        key: 'backgrounds',
      },
      {
        heading: 'Actor rows migration',
        key: 'actors',
      },
      {
        heading: 'Actor variant rows migration',
        key: 'actorVariants',
      },
      {
        heading: 'S3 files migration',
        key: 'files',
      },
    ]

    const getPercent = (progress: AssetMigrationProgress) => {
      if (progress.total === 0) {
        return 0
      }

      return ((progress.current / progress.total) * 100).toFixed(2)
    }

    return { bars, getPercent }
  },
})
