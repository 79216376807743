
import { computed, defineComponent, PropType, reactive, ref, unref, watch } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import { useToaster } from '@/composables/useToaster'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { ALIGN_X, ALIGN_Y, AssetCollection } from '@/modules/ciso/model/asset-collection.model'
import { createCollection, deleteCollection, updateCollection } from '@/modules/ciso/api/assets.api'
import AssetActorsDetailsTable from '@/modules/ciso/components/assets/actors/AssetActorsDetailsTable.vue'
import { AssetActor } from '@/modules/ciso/model/asset-actor.model'
import AssetBackgroundsDetailsTable from '@/modules/ciso/components/assets/backgrounds/AssetBackgroundsDetailsTable.vue'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { AssetsMigrationConfig, migrateAssets } from '@/modules/ciso/api/assets-migration.api'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetCollectionEditor',
  components: {
    AssetBackgroundsDetailsTable,
    AssetActorsDetailsTable,
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    collection: {
      type: Object as PropType<AssetCollection>,
      required: true,
    },
    actors: {
      type: Array as PropType<AssetActor[]>,
      required: true,
    },
    backgrounds: {
      type: Array as PropType<AssetBackground[]>,
      required: true,
    },
    isNewCollection: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:collection'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      showDeleteModal: false,
      showMigrateModal: false,
    })

    const localCollection = computed({
      get: () => props.collection,
      set: (value: AssetCollection) => emit('update:collection', value),
    })

    const selectedActors = ref([])
    const selectedBackgrounds = ref([])
    const migrationDirection = process.env.VUE_APP_ASSET_MIGRATION_DIRECTION
    const migrationConfig = reactive({
      migrateActorVariants: false,
      updateOnConflict: false,
    } as AssetsMigrationConfig)
    const migrationErr = ref('')

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.collection,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveCollectionHandler = async () => {
      try {
        localCollection.value = await updateCollection(localCollection.value)
        data.formState = formSuccess(`Collection "${localCollection.value.name}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `Collection "${localCollection.value.name}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteCollectionHandler = async () => {
      try {
        await deleteCollection(localCollection.value.name)

        const message = `Collection "${localCollection.value.name}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: CISORoute.AssetHome })
      } catch (e) {
        data.formState = formError(
          `Collection "${localCollection.value.name}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createCollectionHandler = async () => {
      try {
        const collection = await createCollection(localCollection.value)

        const message = `Collection "${localCollection.value.name}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: CISORoute.EditAssetCollection,
          params: { collectionName: collection.name },
        })
      } catch (e) {
        data.formState = formError(
          `Collection "${localCollection.value.name}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const migrateSelectedAssets = async () => {
      try {
        migrationErr.value = ''
        await migrateAssets(
          Number(props.collection.id),
          unref(selectedActors),
          unref(selectedBackgrounds),
          unref(migrationConfig)
        )
        await router.push({ name: CISORoute.AssetMigrationStatus })
      } catch (e) {
        migrationErr.value = String(e)
      }
    }

    const goToActorCreator = () => {
      router.push({ name: CISORoute.CreateAssetActor })
    }

    const goToBackgroundCreator = () => {
      router.push({ name: CISORoute.CreateAssetBackground })
    }

    return {
      data,
      localCollection,
      actionHandler,
      saveCollectionHandler,
      deleteCollectionHandler,
      createCollectionHandler,
      goToActorCreator,
      goToBackgroundCreator,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      ALIGN_X,
      ALIGN_Y,
      selectedActors,
      selectedBackgrounds,
      migrateSelectedAssets,
      migrationDirection,
      migrationConfig,
      migrationErr,
    }
  },
})
