import { fileToImg } from '@/utils/img'
import { AssetFile } from '@/modules/ciso/model/asset-file-metadata.interface'

export type ActorAssetType =
  | 'act_atlas_graphic'
  | 'act_atlas_graphic2'
  | 'act_atlas_graphic3'
  | 'act_icon_animated'
  | 'act_icon_animated_portrait'
  | 'act_icon_web'
  | 'act_skeleton'
  | 'act_skeleton_atlas'

export type ActorAssetTypeToHash = { [key: string]: string }

export class AssetActorFile implements AssetFile {
  assetType: string
  s3Key: string | null
  url: string | null
  lastModified: Date | null
  required: boolean

  constructor(doc: AssetActorFile) {
    this.assetType = doc.assetType
    this.s3Key = doc.s3Key
    this.url = doc.url
    this.lastModified = doc.lastModified
    this.required = doc.required
  }
}

export async function validateActorAssetFile(assetType: ActorAssetType, file: File): Promise<true | string> {
  switch (assetType) {
    case 'act_atlas_graphic':
    case 'act_atlas_graphic2':
    case 'act_atlas_graphic3':
      {
        if (file.type !== 'image/png') {
          return 'Invalid file MIME type. Expected image/png.'
        }

        const img = await fileToImg(file)

        if (img.width > 4096 || img.height > 4096) {
          return 'Maximum dimensions are 4096px x 4096px.'
        }

        // Temporarily disabled
        // if (!isPowerOfTwo(img.width) || !isPowerOfTwo(img.height)) {
        //   return 'Image dimensions must be a power of two.'
        // }

        // Temporarily disabled
        // if (img.width !== img.height) {
        //   return 'Image must be a square.'
        // }
      }
      break
    case 'act_skeleton':
      if (file.type !== 'application/json') {
        return 'Invalid file MIME type. Expected application/json.'
      }
      break
    case 'act_skeleton_atlas':
      if (file.type !== 'text/plain' && file.type !== '') {
        return 'Invalid file MIME type. Expected text/plain.'
      }
      break
    case 'act_icon_web':
      {
        if (file.type !== 'image/png') {
          return 'Invalid file MIME type. Expected image/png.'
        }

        const img = await fileToImg(file)

        if (img.width < 400 || img.height < 400 || img.width > 500 || img.height > 500) {
          return 'Image dimensions must be between 400px - 500px.'
        }

        if (img.width !== img.height) {
          return 'Image must be a square.'
        }
      }
      break
    case 'act_icon_animated':
      {
        if (file.type !== 'image/png') {
          return 'Invalid file MIME type. Expected image/png.'
        }

        const img = await fileToImg(file)

        if (img.width < 398 || img.height < 398 || img.width > 500 || img.height > 500) {
          return 'Image dimensions must be between 398px - 500px.'
        }
      }
      break
    case 'act_icon_animated_portrait':
      {
        if (file.type !== 'image/png') {
          return 'Invalid file MIME type. Expected image/png.'
        }

        const img = await fileToImg(file)

        if (img.width < 300 || img.height < 300 || img.width > 1000 || img.height > 1000) {
          return 'Image dimensions must be between 300px - 1000px.'
        }
      }
      break
    default:
      return 'Unknown asset type.'
  }

  return true
}
