
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetCollection } from '@/modules/ciso/model/asset-collection.model'

export default defineComponent({
  name: 'AssetCollectionsDetailsTable',
  props: {
    collections: {
      type: Array as PropType<AssetCollection[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToCollection = (collectionName: string) => {
      router.push({ name: CISORoute.EditAssetCollection, params: { collectionName } })
    }

    return { navigateToCollection }
  },
})
