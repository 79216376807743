import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10a9a86a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "assets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_AssetFilesEditor = _resolveComponent("AssetFilesEditor")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    (_ctx.background.uploadedAssetsCount < _ctx.background.assetSlotsCount)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 0,
          class: "publish-errors-alert",
          color: "warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Background entity is not complete: "),
            _createElementVNode("ul", null, [
              (_ctx.background.uploadedAssetsCount < _ctx.background.assetSlotsCount)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, " You have uploaded " + _toDisplayString(_ctx.background.uploadedAssetsCount) + " of " + _toDisplayString(_ctx.background.assetSlotsCount) + " assets. ", 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("General settings")
            ]),
            _: 1
          }),
          (!_ctx.isNewBackground)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 0,
                bordered: "",
                class: "mb-3",
                label: "ID",
                modelValue: _ctx.localBackground.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localBackground.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Name",
            modelValue: _ctx.localBackground.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localBackground.name) = $event)),
            disabled: !_ctx.isNewBackground
          }, null, 8, ["modelValue", "disabled"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Scale",
            modelValue: _ctx.localBackground.scale,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localBackground.scale) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "0.1",
            min: "-100",
            max: "100"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Offset X",
            modelValue: _ctx.localBackground.offsetX,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localBackground.offsetX) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "1"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Offset Y",
            modelValue: _ctx.localBackground.offsetY,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localBackground.offsetY) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "1"
          }, null, 8, ["modelValue"]),
          (!_ctx.isNewBackground)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AssetFilesEditor, {
                  "collection-name": _ctx.collectionName,
                  "asset-name": _ctx.background.name,
                  "get-files-fn": _ctx.getBackgroundFiles,
                  "upsert-file-fn": _ctx.upsertBackgroundAssetFile,
                  "delete-file-fn": _ctx.deleteBackgroundAssetFile,
                  "asset-validator-fn": _ctx.validateBackgroundAssetFile,
                  onAssetsUpdated: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('refreshBackground')))
                }, null, 8, ["collection-name", "asset-name", "get-files-fn", "upsert-file-fn", "delete-file-fn", "asset-validator-fn"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewBackground)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[6] || (_cache[6] = () => _ctx.actionHandler(_ctx.saveBackgroundHandler)),
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Save ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                  color: "danger",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _ctx.cloneBackground,
                  color: "success",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clone this background ")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isNewBackground)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 3,
                class: "mr-2",
                onClick: _cache[8] || (_cache[8] = () => _ctx.actionHandler(_ctx.createBackgroundHandler)),
                color: "success",
                disabled: _ctx.isFormLoading(_ctx.data.formState)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create ")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to delete this background?",
        onOk: _cache[10] || (_cache[10] = () => _ctx.actionHandler(_ctx.deleteBackgroundHandler))
      }, null, 8, ["modelValue"])
    ])
  ]))
}