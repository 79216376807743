import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBack = _resolveComponent("GoBack")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_va_file_upload = _resolveComponent("va-file-upload")!
  const _component_AssetActorVariantsList = _resolveComponent("AssetActorVariantsList")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_GoBack, {
        to: {
          name: _ctx.CISORoute.EditAssetActor,
          params: { collectionName: _ctx.data.collectionName, actorName: _ctx.data.actorName },
        }
      }, null, 8, ["to"]),
      _createTextVNode(" Actor variants: " + _toDisplayString(_ctx.data.collectionName) + " / " + _toDisplayString(_ctx.data.actorName), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.variants)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.variants
      }, null, 8, ["subject"]),
      _createVNode(_component_FormStateAlert, {
        "form-state": _ctx.data.formState
      }, null, 8, ["form-state"]),
      (_ctx.isLoaded(_ctx.data.variants))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_va_file_upload, {
              modelValue: _ctx.files,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
              dropzone: "",
              "file-types": "csv"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_AssetActorVariantsList, {
              class: "mt-2",
              variants: _ctx.data.variants.data
            }, null, 8, ["variants"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}