export function fileToImg(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file)
    const img = new Image()

    img.onload = function () {
      resolve(img)
      URL.revokeObjectURL(img.src)
    }

    img.src = url
  })
}
