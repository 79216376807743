
import { computed, defineComponent, reactive } from 'vue'
import Panel from '@/components/UI/Panel.vue'
import { CISORoute } from '@/modules/ciso/routes'
import GoBack from '@/components/UI/GoBack.vue'
import { importAssets } from '@/modules/ciso/api/assets.api'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormLoading,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { formatAssetImportReport } from '@/modules/ciso/model/assets-import-report.model'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetImport',
  components: { FormStateAlert, GoBack, Panel },
  setup() {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      overrideExistingAssets: false,
    })

    const files = computed<File[] | File>({
      get: () => [],
      set: (files: File[] | File) => {
        if (Array.isArray(files) && files.length === 1) {
          handleImport(files[0])
        } else if (files) {
          handleImport(files as File)
        }
      },
    })

    const handleImport = async (file: File) => {
      try {
        data.formState = FORM_LOADING_STATE
        const response = await importAssets(file, { overrideExistingAssets: data.overrideExistingAssets })
        data.formState = formSuccess(`Assets imported!\n\n${formatAssetImportReport(response)}`)
      } catch (e) {
        data.formState = formError((e as ApiError).toString())
      }
    }

    return { data, files, CISORoute, isFormLoading }
  },
})
