import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_AssetCollectionsDetailsTable = _resolveComponent("AssetCollectionsDetailsTable")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_component_va_button, {
        to: { name: _ctx.CISORoute.AssetMigrationStatus },
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Migration status")
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_va_button, {
        to: { name: _ctx.CISORoute.AssetImport },
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Import")
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_va_button, {
        to: { name: _ctx.CISORoute.AssetExport },
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Export")
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode(" All asset collections "),
        _createVNode(_component_va_button, {
          class: "ml-4",
          onClick: _ctx.goToCollectionCreator,
          color: "success"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add new collection")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_DataLoader, {
          "is-loading": _ctx.isLoading(_ctx.data.collections)
        }, null, 8, ["is-loading"]),
        _createVNode(_component_DataLoadingError, {
          subject: _ctx.data.collections
        }, null, 8, ["subject"]),
        (_ctx.isLoaded(_ctx.data.collections))
          ? (_openBlock(), _createBlock(_component_AssetCollectionsDetailsTable, {
              key: 0,
              collections: _ctx.sortedCollections
            }, null, 8, ["collections"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}