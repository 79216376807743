import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08df3b39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "status-section"
}
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = {
  key: 3,
  class: "variants-section"
}
const _hoisted_7 = {
  key: 4,
  class: "assets-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_AssetFilesEditor = _resolveComponent("AssetFilesEditor")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    (!_ctx.actor.isPublishable && _ctx.actor.id)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 0,
          class: "publish-errors-alert",
          color: "warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Actor entity is not complete and can't be published: "),
            _createElementVNode("ul", null, [
              (!_ctx.actor.isPublishable)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, " This actor is incomplete, you can't publish it. Make sure you've uploaded all required assets and filled all required fields. "))
                : _createCommentVNode("", true),
              (!_ctx.actor.assetBackgroundId)
                ? (_openBlock(), _createElementBlock("li", _hoisted_2, "You didn't assign any background."))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("General settings")
            ]),
            _: 1
          }),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 0,
                bordered: "",
                class: "mb-3",
                label: "ID",
                modelValue: _ctx.localActor.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localActor.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Collection name",
            "model-value": _ctx.collectionName,
            disabled: ""
          }, null, 8, ["model-value"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Name",
            modelValue: _ctx.localActor.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localActor.name) = $event)),
            disabled: !_ctx.isNewActor
          }, null, 8, ["modelValue", "disabled"]),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 1,
                bordered: "",
                class: "mb-3",
                label: "Assets binary hash",
                modelValue: _ctx.localActor.assetsBinaryHash,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localActor.assetsBinaryHash) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Display name (public)",
            modelValue: _ctx.localActor.displayName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localActor.displayName) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Display name article (a/an/the)",
            modelValue: _ctx.localActor.displayNameArticle,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localActor.displayNameArticle) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Scale",
            modelValue: _ctx.localActor.scale,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localActor.scale) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "0.1",
            min: "-100",
            max: "100"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Offset X",
            modelValue: _ctx.localActor.offsetX,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localActor.offsetX) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "1"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Offset Y",
            modelValue: _ctx.localActor.offsetY,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localActor.offsetY) = $event)),
            modelModifiers: { number: true },
            type: "number",
            step: "1"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_select, {
            bordered: "",
            class: "mb-3",
            label: "Background",
            modelValue: _ctx.selectedBackground,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedBackground) = $event)),
            options: _ctx.backgrounds,
            "text-by": (option) => option.name,
            "track-by": (option) => option.id,
            clearable: ""
          }, _createSlots({ _: 2 }, [
            (_ctx.selectedBackground)
              ? {
                  name: "appendInner",
                  fn: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "background-preview",
                      src: _ctx.getBackgroundAssetFileUrl(_ctx.collectionName, _ctx.selectedBackground.name, 'bg_primary'),
                      alt: ""
                    }, null, 8, _hoisted_3)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue", "options", "text-by", "track-by"]),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_PanelSubheading, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Status")
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_va_select, {
                    bordered: "",
                    class: "mb-3",
                    label: "Status",
                    modelValue: _ctx.localActor.status,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localActor.status) = $event)),
                    options: ['public', 'hidden'],
                    disabled: !_ctx.actor.isPublishable
                  }, null, 8, ["modelValue", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_PanelSubheading, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Variants")
                  ]),
                  _: 1
                }),
                _createVNode(_component_va_button, {
                  class: "mt-3",
                  color: "primary",
                  to: {
              name: _ctx.CISORoute.AssetActorVariants,
              params: { collectionName: _ctx.collectionName, actorName: _ctx.actor.name },
            }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Go to variants ")
                  ]),
                  _: 1
                }, 8, ["to"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_AssetFilesEditor, {
                  "collection-name": _ctx.collectionName,
                  "asset-name": _ctx.actor.name,
                  "get-files-fn": _ctx.getActorFiles,
                  "upsert-file-fn": _ctx.upsertActorAssetFile,
                  "delete-file-fn": _ctx.deleteActorAssetFile,
                  "asset-validator-fn": _ctx.validateActorAssetFile,
                  onAssetsUpdated: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('refreshActor')))
                }, null, 8, ["collection-name", "asset-name", "get-files-fn", "upsert-file-fn", "delete-file-fn", "asset-validator-fn"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isNewActor)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[11] || (_cache[11] = () => _ctx.actionHandler(_ctx.saveActorHandler)),
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Save ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                  color: "danger",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _ctx.cloneActor,
                  color: "success",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clone this actor ")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.isNewActor)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 6,
                class: "mr-2",
                onClick: _cache[13] || (_cache[13] = () => _ctx.actionHandler(_ctx.createActorHandler)),
                color: "success",
                disabled: _ctx.isFormLoading(_ctx.data.formState)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create ")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to delete this actor?",
        onOk: _cache[15] || (_cache[15] = () => _ctx.actionHandler(_ctx.deleteActorHandler))
      }, null, 8, ["modelValue"])
    ])
  ]))
}