
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import { useToaster } from '@/composables/useToaster'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import {
  createBackground,
  deleteBackground,
  deleteBackgroundAssetFile,
  getBackgroundFiles,
  updateBackground,
  upsertBackgroundAssetFile,
} from '@/modules/ciso/api/assets.api'
import AssetFilesEditor from '@/modules/ciso/components/assets/AssetFilesEditor.vue'
import { validateBackgroundAssetFile } from '@/modules/ciso/model/asset-background-file.model'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetBackgroundEditor',
  components: {
    AssetFilesEditor,
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    background: {
      type: Object as PropType<AssetBackground>,
      required: true,
    },
    isNewBackground: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['refreshBackground', 'update:background'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      showDeleteModal: false,
    })

    const localBackground = computed({
      get: () => props.background,
      set: (value: AssetBackground) => emit('update:background', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.background,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveBackgroundHandler = async () => {
      try {
        localBackground.value = await updateBackground(props.collectionName, localBackground.value)
        data.formState = formSuccess(`Background "${localBackground.value.name}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `Background "${localBackground.value.name}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteBackgroundHandler = async () => {
      try {
        await deleteBackground(props.collectionName, localBackground.value.name)

        const message = `Background "${localBackground.value.name}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: CISORoute.EditAssetCollection,
          params: { collectionName: props.collectionName },
        })
      } catch (e) {
        data.formState = formError(
          `Background "${localBackground.value.name}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createBackgroundHandler = async () => {
      try {
        localBackground.value = await createBackground(props.collectionName, localBackground.value)

        const message = `Background "${localBackground.value.name}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: CISORoute.EditAssetBackground,
          params: {
            collectionName: props.collectionName,
            backgroundName: localBackground.value.name,
          },
        })
      } catch (e) {
        data.formState = formError(
          `Background "${localBackground.value.name}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const cloneBackground = () => {
      router.push({
        name: CISORoute.CreateAssetBackground,
        params: {
          collectionName: props.collectionName,
          backgroundName: localBackground.value.name,
        },
      })
    }

    return {
      data,
      localBackground,
      actionHandler,
      saveBackgroundHandler,
      deleteBackgroundHandler,
      createBackgroundHandler,
      cloneBackground,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      getBackgroundFiles,
      upsertBackgroundAssetFile,
      deleteBackgroundAssetFile,
      validateBackgroundAssetFile,
    }
  },
})
