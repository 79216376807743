
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import { useToaster } from '@/composables/useToaster'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { ActorStatus, AssetActor } from '@/modules/ciso/model/asset-actor.model'
import {
  createActor,
  deleteActor,
  deleteActorAssetFile,
  getActorFiles,
  getBackgroundAssetFileUrl,
  updateActor,
  upsertActorAssetFile,
} from '@/modules/ciso/api/assets.api'
import AssetFilesEditor from '@/modules/ciso/components/assets/AssetFilesEditor.vue'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { validateActorAssetFile } from '@/modules/ciso/model/asset-actor-file.model'
import { ApiError } from '@/api/_shared'

export default defineComponent({
  name: 'AssetActorEditor',
  components: {
    AssetFilesEditor,
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    actor: {
      type: Object as PropType<AssetActor>,
      required: true,
    },
    backgrounds: {
      type: Object as PropType<AssetBackground[]>,
      required: true,
    },
    isNewActor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['refreshActor', 'update:actor'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      showDeleteModal: false,
    })

    const localActor = computed({
      get: () => props.actor,
      set: (value: AssetActor) => emit('update:actor', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.actor,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const selectedBackground = computed({
      get() {
        return props.backgrounds.find((b) => b.id === localActor.value.assetBackgroundId) || null
      },
      set(value: AssetBackground | null) {
        localActor.value.assetBackgroundId = value?.id ?? null
      },
    })

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveActorHandler = async () => {
      try {
        if (!localActor.value.isPublishable) {
          localActor.value.status = ActorStatus.Hidden
        }

        localActor.value = await updateActor(props.collectionName, localActor.value)
        data.formState = formSuccess(`Actor "${localActor.value.displayName}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `Actor "${localActor.value.displayName}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteActorHandler = async () => {
      try {
        await deleteActor(props.collectionName, localActor.value.name)

        const message = `Actor "${localActor.value.displayName}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: CISORoute.EditAssetCollection, params: { collectionName: props.collectionName } })
      } catch (e) {
        data.formState = formError(
          `Actor "${localActor.value.displayName}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createActorHandler = async () => {
      try {
        const actor = await createActor(props.collectionName, localActor.value)

        const message = `Actor "${localActor.value.displayName}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: CISORoute.EditAssetActor,
          params: { collectionName: props.collectionName, actorName: actor.name },
        })
      } catch (e) {
        data.formState = formError(
          `Actor "${localActor.value.displayName}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const cloneActor = () => {
      router.push({
        name: CISORoute.CreateAssetActor,
        params: { collectionName: props.collectionName, actorName: localActor.value.name },
      })
    }

    return {
      data,
      localActor,
      actionHandler,
      saveActorHandler,
      deleteActorHandler,
      createActorHandler,
      cloneActor,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      getActorFiles,
      upsertActorAssetFile,
      deleteActorAssetFile,
      validateActorAssetFile,
      getBackgroundAssetFileUrl,
      selectedBackground,
      CISORoute,
    }
  },
})
