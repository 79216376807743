
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { CISORoute } from '@/modules/ciso/routes'
import { AssetBackground } from '@/modules/ciso/model/asset-background.model'
import { getBackgroundAssetFileUrl } from '@/modules/ciso/api/assets.api'

export default defineComponent({
  name: 'AssetBackgroundsDetailsTable',
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    backgrounds: {
      type: Array as PropType<AssetBackground[]>,
      required: true,
    },
  },
  emits: ['selectedRowsIds'],
  setup(props, { emit }) {
    const router = useRouter()

    const selectedRows = reactive({} as { [key: string]: boolean })

    watch(selectedRows, () => {
      emit(
        'selectedRowsIds',
        Object.entries(selectedRows)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, value]) => Boolean(value))
          .map(([key]) => Number(key))
      )
    })

    const selectAll = () => {
      props.backgrounds.forEach((bg) => (selectedRows[String(bg.id)] = true))
    }

    const deselectAll = () => {
      props.backgrounds.forEach((bg) => (selectedRows[String(bg.id)] = false))
    }

    const allSelected = computed(() => Object.values(selectedRows).filter(Boolean).length >= props.backgrounds.length)

    const navigateToBackground = (collectionName: string, backgroundName: string) => {
      router.push({ name: CISORoute.EditAssetBackground, params: { collectionName, backgroundName } })
    }

    const hasAllAssets = (bg: AssetBackground) => bg.uploadedAssetsCount >= bg.assetSlotsCount

    return {
      navigateToBackground,
      hasAllAssets,
      getBackgroundAssetFileUrl,
      selectedRows,
      selectAll,
      deselectAll,
      allSelected,
    }
  },
})
